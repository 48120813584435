<template v-if="documents.length > 0">
  <div class="spacer-md">
    <h2>{{ documentType[this.$getLangKey()] }}</h2>
    <!--    <v-layout row justify-center>-->
    <!--      <v-overlay v-model="loading" persistent fullscreen id="loading-dialog" content-class="loading-dialog">-->
    <!--        <v-container fill-height>-->
    <!--          <v-layout row justify-center align-center>-->
    <!--            <v-progress-circular indeterminate color="secondary"></v-progress-circular>-->
    <!--          </v-layout>-->
    <!--        </v-container>-->
    <!--      </v-overlay>-->
    <!--    </v-layout>-->
    <v-list two-line density="compact" v-if="documents.length > 0">
      <template v-for="(item, index) in documents">
        <v-list-subheader v-if="item.header" :key="item.header">
          {{ item.header }}
        </v-list-subheader>
        <v-list-item
          class="single-inbox-style"
          :href="protocol + item.uri"
          target="_blank"
          @click="setDocumentViewed(item)"
          @click.right="setDocumentViewed(item)"
        >
          <template v-slot:prepend>
            <v-icon size="24px">mdi-file-outline</v-icon>
          </template>

          <v-list-item-subtitle :class="{ link: item.viewed == null }"><span v-html="createTitle(item)" /></v-list-item-subtitle>
          <v-list-item-subtitle :class="{ link: item.viewed == null }"><span v-html="$formatDate(item.creation)" /></v-list-item-subtitle>
        </v-list-item>
        <v-divider v-if="index < documents.length - 1" :key="index" class="inbox-divider"></v-divider>
      </template>
    </v-list>

    <div class="link align-right" v-if="documents.length > 4">
      <span v-html="$t('more')" @click="searchByType(documentType.name)"></span>
    </div>
  </div>
</template>

<script lang="ts">
import { showError } from '@/services/axios'
import { apiURL, protocol } from '@/main'
import { app } from '../../main'
import { format } from 'date-fns'
import { defineComponent } from 'vue'
import { useLegalEntityStore } from '@/store/LegalEntityStore'
export default defineComponent({
  name: 'singleInbox',
  props: ['documentType', 'item'],
  data() {
    return {
      documents: [],
      loading: false,
      protocol: protocol,
      app: app
    }
  },
  computed: {},
  watch: {},
  methods: {
    searchByType(item) {
      this.$emit('full-search', item)
    },
    async setDocumentViewed(item) {
      if (this.checkIfUserIsAbleToSetViewed && item.viewed === null) {
        this.loading = true
        try {
          item.viewed = new Date().toISOString()
          await this.axios.patch(apiURL + '/documents/' + `${item.id}` + '/setViewed', {})
        } catch (e) {
          showError(e)
        } finally {
          this.loading = false
        }
      }
    },
    $formatDate(date) {
      return format(date, 'yyyy-MM-dd')
    },
    createTitle(item) {
      return `${item.ownerName ? item.ownerName + ' - ' : ''}${item.creatorName ? item.creatorName + ' - ' : ''}${item.fileName ? item.fileName + ' - ' : ''}${
        item.dated ? format(item.dated, 'MM.yyyy') + '\n' : ''
      }`
    },
    async loadDocument() {
      try {
        this.loading = true
        const result = await this.axios.get(`${apiURL}/documents/search/findByDocumentTypeGroup`, {
          params: {
            page: 0,
            size: 5,
            documentTypeGroup: this.documentType.name
          }
        })
        return result.data.content
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    domainId() {
      const legalEntityStore = useLegalEntityStore()
      return legalEntityStore.getSelectedPersonId
    },
    originId() {
      const legalEntityStore = useLegalEntityStore()
      return legalEntityStore.getOriginId
    },
    checkIfUserIsAbleToSetViewed() {
      return this.domainId() === this.originId()
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.documents = await this.loadDocument()
    })
  }
})
</script>

<style lang="scss" scoped>
.single-inbox-style {
  padding-left: 0 !important;
}

.inbox-divider {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.15) !important;
}
</style>
