<template>
  <v-container>
    <div>
      <v-row loading="loading" class="row spacer-md beta" v-if="$getBeta()">
        <v-col v-for="(document, i) in documentTypeGroups" :key="i" xs="12" md="6" lg="2">
          <single-inbox v-bind:documentType="document" @full-search="searchByGroup" />
        </v-col>
      </v-row>
    </div>

    <div>
      <v-row loading="loading" class="row spacer-md beta" v-if="$getBeta() && legacyBoxEnabled()">
        <v-col xs="12" md="6" lg="2"
          ><h2 v-html="$t('card_legacy_box_title')"></h2>
          <p v-html="$t('card_legacy_box_text')"></p>
          <v-btn color="primary" @click="gotoLegacyBox"><span v-html="$t('card_legacy_box_button')" /></v-btn>
        </v-col>
      </v-row>
    </div>

    <div>
      <v-row class="row spacer-md">
        <v-col xs="12" md="6" lg="2">
          <search-document ref="search-document" @keyup.enter="search" />
        </v-col>
      </v-row>
      <v-btn color="primary" @click="search"><span v-html="$t('documents_search')" /></v-btn>
      <v-btn color="secondary" @click="reset"><span v-html="$t('documents_reset')" /></v-btn>

      <v-data-table-server
        :headers="fields"
        :loading="loading"
        :items-length="totalElements"
        @update:itemsPerPage="routerPushSize"
        @update:page="routerPushPage"
        @update:sort-by="routerPushDTSort"
        :sort-by="toDTSort(query.sort)"
        :page="getDTPageFromRoute(query.page)"
        :items-per-page="query.size"
        :items-per-page-options="$itemsPerPageOptions"
        :items="items"
        :fields="fields"
        density="compact"
      >
        <template v-slot:[`item.url`]="{ item }">
          <template v-if="item.id">
            <a
              :class="{ unlink: item.viewed !== null }"
              :href="protocol + item.url"
              class="subheading mx-3"
              target="_blank"
              @click.right="setDocumentViewed(item)"
              @click="setDocumentViewed(item)"
            >
              {{ item.documentFilename }}
            </a>
          </template>
        </template>
        <template v-slot:[`item.created`]="{ item }">
          <template v-if="item.created">
            <span>{{ format(item.created, 'yyyy-MM-dd') }}</span>
          </template>
        </template>
        <template v-slot:[`item.dated`]="{ item }">
          <template v-if="item.dated">
            <span>{{ format(item.dated, 'yyyy-MM-dd') }}</span>
          </template>
        </template>
      </v-data-table-server>
    </div>
  </v-container>
</template>

<script lang="ts">
import { apiURL, protocol } from '@/main'
import { showError } from '@/services/axios'
import searchDocument from '@/components/searchCards/searchDocument'
import { privileges } from '@/services/privileges'
import singleInbox from '../singleInbox'
import { format } from 'date-fns'
import { useDocumentTypeGroupsStore } from '@/store/enums/DocumentTypeGroupsStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'inbox_read',
  data() {
    return {
      items: [] as any[],
      loading: false,
      format: format,
      documentType: this.documentTypes,
      protocol: protocol,
      privileges: privileges,
      fields: [
        {
          title: this.$t('search_document_context'),
          key: 'documentType' + this.$getUpLangKey(),
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('document_title'),
          key: 'title',
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('document_senderName'),
          key: 'senderName',
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('document_entry'),
          key: 'created',
          sortable: false,
          align: 'left'
        },
        {
          title: this.$t('document_dated'),
          key: 'dated',
          sortable: true,
          align: 'left'
        },
        {
          title: 'Url',
          key: 'url',
          sortable: true,
          align: 'left'
        }
      ],
      totalElements: 0
    }
  },
  computed: {
    documentTypeGroups() {
      return useDocumentTypeGroupsStore().documentTypeGroups
    },
    params() {
      return {
        documentTypeGroup: this.$store.state.SearchDocumentStore.documentTypeGroup,
        legalEntityId: this.$store.state.SearchDocumentStore.legalEntityId,
        filename: this.$store.state.SearchDocumentStore.filename,
        type: this.$store.state.SearchDocumentStore.type,
        from: this.$store.state.SearchDocumentStore.from,
        until: this.$store.state.SearchDocumentStore.until
      }
    },
    query(): any {
      return {
        ...this.params,
        ...this.getJavaPageOptions()
      }
    }
  },
  components: {
    searchDocument,
    singleInbox
  },
  methods: {
    searchByGroup(documentTypeGroup) {
      console.log(documentTypeGroup, 'document tye group, search by'), (this.$store.state.SearchDocumentStore.documentTypeGroup = documentTypeGroup)
      this.search()
    },
    search() {
      this.$router
        .push({
          path: this.$route.path,
          query: this.query
        })
        .catch((e) => {
          // route duplicated
          this.load()
        })
    },
    async load() {
      /* backend binding */
      this.loading = true
      try {
        this.items = []
        const response = await this.axios.get(apiURL + '/documents/inbox', {
          params: this.query
        })
        const items = await response.data.content
        this.items = Array.isArray(items) ? items : []
        this.totalElements = this.checkPage(response.data.totalElements)
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    checkIfUserIsAbleToSetViewed() {
      return this.domainId() === this.originId()
    },
    async setDocumentViewed(item) {
      if (this.checkIfUserIsAbleToSetViewed && item.viewed === null) {
        this.loading = true
        try {
          item.viewed = new Date().toISOString()
          await this.axios.patch(apiURL + '/documents/' + `${item.id}` + '/setViewed', {})
        } catch (e) {
          showError(e)
        } finally {
          this.loading = false
        }
      }
    },
    calculateDateFrom() {
      let today = new Date()
      let marchOfCurrentYear = new Date(new Date().getFullYear(), 2, 1) // march 1
      if (today > marchOfCurrentYear) {
        this.$store.state.SearchDocumentStore.from = `${new Date().getFullYear()}-01-01` // january 1, current year
      } else {
        this.$store.state.SearchDocumentStore.from = `${new Date().getFullYear() - 1}-01-01` // january 1, last year
      }
    },
    reset() {
      this.$store.state.SearchDocumentStore.documentTypeGroup = null
      this.$store.state.SearchDocumentStore.legalEntityId = null
      this.$store.state.SearchDocumentStore.filename = null
      this.$store.state.SearchDocumentStore.type = null
      this.$store.state.SearchDocumentStore.from = null
      this.$store.state.SearchDocumentStore.until = null
    },
    legacyBoxEnabled() {
      return (
        this.$legacyEnabled() &&
        this.privileges.has({
          path: '/legacy/dbp/369',
          permission: 'read'
        })
      )
    },
    gotoLegacyBox() {
      this.$gotoLegacy('dbp', 369)
    }
  },
  watch: {
    '$route.query': {
      handler(newValue) {
        this.load()
      },
      deep: true
    }
  },
  beforeMount() {
    if (this.$privileges.has({ path: '/documents', permission: 'read' })) {
      useDocumentTypeGroupsStore().fetchDocumentTypeGroups()
    }
  },
  mounted() {
    this.calculateDateFrom()
    if (this.$route.query.size) {
      this.load()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.unlink {
  color: $dbm-primary-grey !important;
  cursor: pointer;
}
</style>
